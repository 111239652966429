import config from 'config';
import { isHash, isSession } from 'helpers/login';
import PropTypes from 'helpers/PropTypes';
import { getStorageTimestamp } from 'helpers/storage';
import { last } from 'lodash';
import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router';

const { pages } = config;
const { path: loginPath } = last(pages);

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { computedMatch: { params: { moduleHash, ...restParams } = {} } = {} } = rest || {};

  const timestamp =
    useSelector(({ login: { timestamp: stateTimestamp } }) => stateTimestamp) ||
    getStorageTimestamp();

  const render = useCallback(
    ({ location, ...props }) =>
      isSession(timestamp) || isHash(moduleHash) ? (
        <Component {...props} params={restParams} />
      ) : (
        <Redirect
          to={{
            pathname: loginPath,
            state: { from: location },
          }}
        />
      ),
    [moduleHash, restParams, timestamp],
  );

  return <Route {...rest} render={render} />;
};

PrivateRoute.propTypes = {
  component: PropTypes.object.isRequired,
};

export default PrivateRoute;
