import { startSession } from 'actions';
import { setStorageTimestamp } from 'helpers/storage';
import { put, select, takeEvery } from 'redux-saga/effects';

function* setSession({ payload: { isFirstRendering } }) {
  const { timestamp } = yield select(({ login }) => login);
  if (!isFirstRendering && timestamp) {
    yield put(startSession());
    setStorageTimestamp();
  }
}

function* sagas() {
  yield takeEvery('@@router/LOCATION_CHANGE', setSession);
}

export default sagas;
