import config from 'config';
import { map } from 'lodash';

const {
  localStorage: { password, timestamp, username },
} = config;
const { localStorage } = window;

const getItem = (name) => localStorage.getItem(name);
const setItem = (name, value) => localStorage.setItem(name, value);

export const getStoragePassword = () => getItem(password);
export const getStorageTimestamp = () => parseInt(getItem(timestamp), 10);
export const getStorageUsername = () => getItem(username);

export const setStoragePassword = (value) => setItem(password, value);
export const setStorageTimestamp = () => setItem(timestamp, new Date().getTime());
export const setStorageUsername = (value) => setItem(username, value);
export const resetStorage = () =>
  map([password, timestamp, username], (item) => localStorage.removeItem(item));
