import { fulfilled, initialState, pending, rejected } from 'helpers/resources';
import typeToReducer from 'type-to-reducer';

export const SCHEMAS_RESOURCE = 'schemas';
export const FETCH_SCHEMAS = 'FETCH_SCHEMAS';
export const SAVE_SCHEMAS = 'SAVE_SCHEMAS';
export const CREATE_SCHEMAS = 'CREATE_SCHEMAS';
export const DELETE_SCHEMAS = 'DELETE_SCHEMAS';
export const SAVE_PRICES = 'SAVE_PRICES';

export default typeToReducer(
  {
    [FETCH_SCHEMAS]: {
      PENDING: (state) => pending(state),
      FULFILLED: (state, payload) => fulfilled(state, payload),
      REJECTED: (state, payload) => rejected(state, payload),
    },
  },
  initialState(),
);
