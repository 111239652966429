import GenericModal from 'components/Modal/Generic';
import { STATUS_OK, TYPE_OK } from 'components/Modal/Types';
import PropTypes from 'helpers/PropTypes';
import { noop } from 'lodash';
import React from 'react';

const Text = ({ closeModal, data: { title, text }, onClose, onConfirm, status, type }) => (
  <GenericModal
    closeModal={closeModal}
    customTitle={title}
    onClose={onClose}
    onConfirm={onConfirm}
    status={status}
    type={type}
  >
    <p>{text}</p>
  </GenericModal>
);

Text.propTypes = {
  closeModal: PropTypes.func,
  data: PropTypes.object,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  status: PropTypes.number,
  type: PropTypes.string,
};

Text.defaultProps = {
  closeModal: noop,
  data: {},
  onClose: noop,
  onConfirm: noop,
  status: STATUS_OK,
  type: TYPE_OK,
};

export default Text;
