import axios from 'axios';
import config from 'config';
import { noop } from 'lodash';

const {
  api: { cephalometry, host, photo },
} = config;

export const postCephalometry = (data) =>
  axios.post(`${host}${cephalometry}`, data, {
    headers: {
      'Content-Type': 'application/json',
    },
  });

export const postImage = (description, file, miniFile, patientId, title, onProgress = noop) => {
  const data = new FormData();
  data.append('photo', file);
  data.append('photo', file, miniFile);

  return axios.post(`${host}${photo}`, data, {
    headers: { 'Content-Type': 'multipart/form-data' },
    params: {
      description,
      patientId,
      title,
    },
    onUploadProgress: (progressEvent) => onProgress && onProgress(progressEvent),
  });
};

export const deleteImage = (id) => axios.delete(`${host}${photo}/${id}`);
