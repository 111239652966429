import config from 'config';
import { routerMiddleware } from 'connected-react-router';
import reducers from 'reducers';
import { applyMiddleware, compose, createStore as _createStore } from 'redux';
import promiseMiddleware from 'redux-promise-middleware';
import createSagaMiddleware from 'redux-saga';
import sagas from 'sagas';

const createStore = (history) => {
  const sagaMiddleware = createSagaMiddleware();
  const middleware = [promiseMiddleware, routerMiddleware(history), sagaMiddleware];
  const enhancers = [];
  if (config.isDevelopment) {
    const { __REDUX_DEVTOOLS_EXTENSION__ } = window;

    if (typeof __REDUX_DEVTOOLS_EXTENSION__ === 'function') {
      enhancers.push(__REDUX_DEVTOOLS_EXTENSION__());
    }
  }
  const store = _createStore(
    reducers(history),
    {},
    config.isDevelopment
      ? compose(applyMiddleware(...middleware), ...enhancers)
      : applyMiddleware(...middleware),
  );
  sagaMiddleware.run(sagas);
  return store;
};

export default createStore;
