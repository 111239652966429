import * as Modals from 'components/Modal';
import { MODAL_TEXT, STATUS_OK, TYPE_OK } from 'components/Modal/Types';
import { noop } from 'lodash';
import React, { useCallback, useMemo, useState } from 'react';
import { PortalWithState } from 'react-portal';

const withModal = (Component) => (props) => {
  const [modal, setModal] = useState(MODAL_TEXT);
  const [status, setStatus] = useState(STATUS_OK);
  const [data, setData] = useState({});
  const [type, setType] = useState(TYPE_OK);
  const [onClose, setOnClose] = useState(noop);
  const [onConfirm, setOnConfirm] = useState(noop);

  const openModal = useCallback(
    (openPortal) =>
      ({
        modal: newModal = MODAL_TEXT,
        onClose: newOnClose = noop,
        onConfirm: newOnConfirm = noop,
        status: newStatus = STATUS_OK,
        data: newData = {},
        type: newType = TYPE_OK,
      }) => {
        setModal(newModal);
        setOnClose(() => newOnClose);
        setOnConfirm(() => newOnConfirm);
        setStatus(newStatus);
        setData(newData);
        setType(newType);
        setTimeout(() => openPortal(), 50);
      },
    [setType, setData, setOnClose],
  );

  const Modal = useMemo(() => Modals[modal], [modal]);

  return (
    <PortalWithState closeOnOutsideClick closeOnEsc>
      {({ closePortal, openPortal, portal }) => (
        <>
          <Component openModal={openModal(openPortal)} {...props} />
          {portal(
            <Modal
              closeModal={closePortal}
              onClose={onClose}
              onConfirm={onConfirm}
              status={status}
              data={data}
              type={type}
            />,
          )}
        </>
      )}
    </PortalWithState>
  );
};

export default withModal;
