/* eslint-disable no-await-in-loop */
import { InboxOutlined } from '@ant-design/icons';
import styled from '@emotion/styled';
import { deleteUploadingModel, setUploadingModel } from 'actions';
import { notification, Upload } from 'antd';
import { postImage } from 'api';
import Generic from 'components/Modal/Generic';
import { STATUS_OK, TYPE_YES_NO } from 'components/Modal/Types';
import PropTypes from 'helpers/PropTypes';
import translations from 'locales/main.yaml';
import { noop } from 'lodash';
import React, { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

const { Dragger } = Upload;

const StyledDraggerWrapper = styled.div`
  margin-bottom: 1.5rem;
`;

const GalleryUpload = ({ closeModal, data: { patientId }, onConfirm, status, type }) => {
  const [filesQueue, setFilesQueue] = useState([]);
  const dispatch = useDispatch();
  const {
    global: { cancel: cancelTrans, error: errorTrans, save: saveTrans },
    modules: {
      gallery: {
        modal: { description: descriptionTrans, title: titleTrans },
      },
    },
  } = useMemo(() => translations, []);

  const draggerProps = useMemo(
    () => ({
      name: 'file',
      multiple: true,
      listType: 'picture',
      showUploadList: {
        showDownloadIcon: false,
        showRemoveIcon: false,
      },
      beforeUpload: (file) => {
        setFilesQueue((current) => [file, ...current]);

        return false;
      },
    }),
    [patientId],
  );

  const handleOnConfirm = async () => {
    for (let i = 0; i < filesQueue.length; i += 1) {
      const { name } = filesQueue[i];

      await postImage('', filesQueue[i], 'miniImage.jpg', patientId, '', ({ loaded, total }) => {
        dispatch(
          setUploadingModel({
            fileKey: name,
            title: name,
            progress: Math.round((loaded * 100) / total),
          }),
        );
      })
        .catch(({ message }) => {
          notification.error({
            message: `${errorTrans} ${name}`,
            description: message,
            duration: 10,
          });
        })
        .finally(() => {
          dispatch(deleteUploadingModel(name));
          notification.close(name);
        });
    }

    onConfirm();
  };

  return (
    <Generic
      buttonsTranslation={{ no: cancelTrans, yes: saveTrans }}
      closeModal={closeModal}
      customTitle={titleTrans}
      onClose={closeModal}
      onConfirm={handleOnConfirm}
      status={status}
      type={type}
      withStatusIcon={false}
      disabled={!filesQueue.length}
    >
      <StyledDraggerWrapper>
        <Dragger {...draggerProps}>
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">{descriptionTrans}</p>
        </Dragger>
      </StyledDraggerWrapper>
    </Generic>
  );
};

GalleryUpload.propTypes = {
  closeModal: PropTypes.func,
  data: PropTypes.object,
  onConfirm: PropTypes.func,
  status: PropTypes.number,
  type: PropTypes.string,
};

GalleryUpload.defaultProps = {
  closeModal: noop,
  data: {},
  onConfirm: noop,
  status: STATUS_OK,
  type: TYPE_YES_NO,
};

export default GalleryUpload;
