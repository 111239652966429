import { fulfilled, initialState, pending, rejected } from 'helpers/resources';
import produce from 'immer';
import { findIndex, remove } from 'lodash';
import typeToReducer from 'type-to-reducer';

export const MODELS_RESOURCE = 'models';
export const FETCH_STL_MODELS = 'GET_STL_MODELS';
export const POST_STL_MODEL = 'POST_STL_MODEL';
export const DELETE_STL_MODEL = 'DELETE_STL_MODEL';
export const SET_UPLOADING_MODEL = 'SET_UPLOADING_MODEL';
export const DELETE_UPLOADING_MODEL = 'DELETE_UPLOADING_MODEL';

export default typeToReducer(
  {
    [FETCH_STL_MODELS]: {
      PENDING: (state) => pending(state),
      FULFILLED: (state, { payload: { data } }) =>
        fulfilled(state, {
          payload: { data },
        }),
      REJECTED: (state, payload) => rejected(state, payload),
    },
    [POST_STL_MODEL]: {
      FULFILLED: (state, { payload: { data: modelData } }) =>
        produce(state, ({ data }) => {
          data.push(modelData);
        }),
    },
    [DELETE_STL_MODEL]: {
      FULFILLED: (
        state,
        {
          payload: {
            data: { id: removedId },
          },
        },
      ) =>
        produce(state, ({ data }) => {
          remove(data, ({ id }) => id === removedId);
        }),
      REJECTED: (state, payload) => rejected(state, payload),
    },
    [SET_UPLOADING_MODEL]: (
      state,
      { payload: { fileKey: payloadFileKey, progress, ...payloadRest } },
    ) =>
      produce(state, ({ uploading }) => {
        const index = findIndex(uploading, ({ fileKey }) => fileKey === payloadFileKey);
        if (index > -1) {
          uploading[index].progress = progress;
        } else {
          uploading.push({ fileKey: payloadFileKey, progress, ...payloadRest });
        }
      }),
    [DELETE_UPLOADING_MODEL]: (state, { payload }) =>
      produce(state, ({ uploading }) => {
        remove(uploading, ({ fileKey }) => fileKey === payload);
      }),
  },
  initialState({ uploading: [] }),
);
