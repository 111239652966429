import {
  CheckSquareOutlined,
  CloseOutlined,
  StopOutlined,
  WarningOutlined,
} from '@ant-design/icons';
import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { Button } from 'antd';
import { STATUS_OK, TYPE_OK, TYPE_QUESTION, TYPE_YES_NO } from 'components/Modal/Types';
import { colors } from 'global/variables';
import PropTypes from 'helpers/PropTypes';
import translations from 'locales/main.yaml';
import { noop } from 'lodash';
import React, { useCallback, useMemo } from 'react';

const { borderColor, green, red, primaryColor, secondaryColor } = colors;

const MAX_MODAL_HEIGHT = 512;
const RIBBON_HEIGHT = 38;

const StyledModal = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: ${borderColor};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledContainer = styled.div`
  background-color: white;
  border-radius: 5px;
  width: 20rem;
  text-align: center;
  max-height: ${MAX_MODAL_HEIGHT}px;
`;

const StyledRibbon = styled.div`
  width: 100%;
  background-color: ${primaryColor};
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledTitle = styled.span`
  color: white;
  padding: 0.5rem;
  cursor: pointer;
`;

const StyledCloseIcon = styled(CloseOutlined)`
  color: white;
  padding: 0.5rem;
`;

const StyledContent = styled.div`
  padding: 1rem;
  border-bottom: 1px solid ${borderColor};
  border-left: 1px solid ${borderColor};
  border-right: 1px solid ${borderColor};
  max-height: calc(${MAX_MODAL_HEIGHT}px - ${RIBBON_HEIGHT}px);
  overflow-y: auto;
`;

const styleStatusIcon = css`
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
`;

const StyledOkStatusIcon = styled(CheckSquareOutlined)`
  color: ${green};
`;

const StyledWarningStatusIcon = styled(WarningOutlined)`
  color: ${secondaryColor};
`;

const StyledErrorStatusIcon = styled(StopOutlined)`
  color: ${red};
`;

const statusIcons = [
  <StyledOkStatusIcon css={styleStatusIcon} />,
  <StyledWarningStatusIcon css={styleStatusIcon} />,
  <StyledErrorStatusIcon css={styleStatusIcon} />,
];

const {
  global: { cancel, confirm, no: noTrans, title, yes: yesTrans },
} = translations;

const StyledButton = styled(Button)`
  margin: 0 0.5rem;
  min-width: 5rem;
`;

const Generic = ({
  buttonsTranslation: {
    cancel: cancelCustomTrans,
    no: noCustomTrans,
    ok: okCustomTrans,
    yes: yesCustomTrans,
  },
  children,
  closeModal,
  customTitle,
  disabled,
  onClose,
  onConfirm,
  status,
  type,
  withStatusIcon,
}) => {
  const getButton = useCallback(
    (buttonText = '', onClick = noop, isDisabled = false, buttonType = 'primary') => (
      <StyledButton size="large" disabled={isDisabled} type={buttonType} onClick={onClick}>
        {buttonText}
      </StyledButton>
    ),
    [],
  );
  // todo: refactor common button for ok/yes, no/cancel - translation is parameter
  const okButton = useMemo(
    () =>
      getButton(
        okCustomTrans || confirm,
        () => {
          const result = onClose();
          if (result !== false) {
            // TODO: fix to better validate forms
            closeModal();
          }
        },
        disabled,
      ),
    [closeModal, disabled, getButton, onClose],
  );

  const cancelButton = useMemo(
    () => getButton(cancelCustomTrans || cancel, closeModal, false, 'secondary'),
    [getButton, closeModal],
  );

  const yesButton = useMemo(
    () =>
      getButton(
        yesCustomTrans || yesTrans,
        () => {
          onConfirm();
          closeModal();
        },
        disabled,
      ),
    [closeModal, disabled, getButton, onConfirm],
  );

  const noButton = useMemo(
    () =>
      getButton(
        noCustomTrans || noTrans,
        () => {
          onClose();
          closeModal();
        },
        false,
        'secondary',
      ),
    [getButton, closeModal, onClose],
  );

  const getButtons = useCallback(() => {
    switch (type) {
      case TYPE_QUESTION:
        return (
          <div>
            {okButton}
            {cancelButton}
          </div>
        );
      case TYPE_YES_NO:
        return (
          <div>
            {yesButton}
            {noButton}
          </div>
        );
      default:
        return okButton;
    }
  }, [cancelButton, noButton, okButton, type, yesButton]);

  const buttons = useMemo(() => getButtons(), [getButtons]);
  return (
    <StyledModal>
      <StyledContainer>
        <StyledRibbon>
          <StyledTitle>{customTitle || title}</StyledTitle>
          <StyledCloseIcon onClick={closeModal} />
        </StyledRibbon>
        <StyledContent>
          {withStatusIcon && statusIcons[status]}
          {children}
          {buttons}
        </StyledContent>
      </StyledContainer>
    </StyledModal>
  );
};

Generic.propTypes = {
  buttonsTranslation: PropTypes.object,
  children: PropTypes.children,
  closeModal: PropTypes.func,
  customTitle: PropTypes.string,
  disabled: PropTypes.bool,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  status: PropTypes.number,
  type: PropTypes.string,
  withStatusIcon: PropTypes.bool,
};

Generic.defaultProps = {
  buttonsTranslation: {},
  children: null,
  closeModal: noop,
  customTitle: '',
  disabled: false,
  onClose: noop,
  onConfirm: noop,
  status: STATUS_OK,
  type: TYPE_OK,
  withStatusIcon: true,
};

export default Generic;
