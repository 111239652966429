import { css, Global } from '@emotion/core';
import loadable from '@loadable/component';
import PrivateRoute from 'components/PrivateRoute';
import config from 'config';
import { ConnectedRouter } from 'connected-react-router';
import App from 'containers/App';
import Login from 'containers/Login';
import { colors } from 'global/variables';
import { isModule } from 'helpers/login';
import PropTypes from 'helpers/PropTypes';
import { createBrowserHistory } from 'history';
import { flatten, initial, last, map, snakeCase } from 'lodash';
import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { Route, Switch, useLocation } from 'react-router';
import { animated, useTransition } from 'react-spring';
import registerServiceWorker from 'registerServiceWorker';
import createStore from 'store';
import { QueryParamProvider } from 'use-query-params';

const { textColor } = colors;
const history = createBrowserHistory();
const createdStore = createStore(history);
const { pages, modules } = config;
const containers = initial(pages);
const { path: loginPath } = last(pages);

const globalStyles = css`
  body {
    margin: 0;
    padding: 0;
    font-family: 'Oswald', sans-serif;
  }

  #root {
    height: 100%;
  }

  h1,
  h2,
  h3 {
    font-weight: bold;
    margin: 0 0 2rem 0;
    text-align: center;
    color: ${textColor};
  }
`;

const createPrivateRoute = ({ component, path, subPages }) => (
  <PrivateRoute key={snakeCase(component)} path={path} exact={!!subPages} component={component} />
);

const createContainer = ({ component, ...container }) =>
  createPrivateRoute({
    component: loadable(() => import(`containers/${component}`)),
    ...container,
  });

const createModules = ({ component, ...module }) =>
  createPrivateRoute({
    component: loadable(() => import(`modules/${component}`)),
    ...module,
  });

createPrivateRoute.propTypes = {
  component: PropTypes.object.isRequired,
  path: PropTypes.string.isRequired,
  subPages: PropTypes.array,
};

createPrivateRoute.defaultProps = {
  subPages: [],
};

const AppSwitch = () => {
  const location = useLocation();
  const transitions = useTransition(location, ({ pathname }) => pathname, {
    from: { opacity: 0, transform: 'translate(100%, 0)' },
    enter: { opacity: 1, transform: 'translate(0, 0)' },
    leave: { display: 'none' },
  });
  const { pathname } = location;
  return !isModule(pathname) ? (
    <App>
      {transitions.map(({ item, props, key }) => (
        <animated.div key={key} style={{ display: 'flex', justifyContent: 'center', ...props }}>
          <Switch location={item}>
            <Route exact path="/" component={Login} />
            <Route exact path={loginPath} component={Login} />
            {map(containers, ({ enabled, path, subPages, ...container }) => {
              const routes = [];
              if (enabled) {
                routes.push(createContainer({ path, subPages, ...container }));
                if (subPages) {
                  routes.push(
                    map(subPages, ({ path: subPagePath, ...subContainer }) =>
                      createContainer({
                        path: `${path}${subPagePath}`,
                        subPages,
                        ...subContainer,
                      }),
                    ),
                  );
                }
              }
              return flatten(routes);
            })}
            <PrivateRoute component={loadable(() => import('containers/NotFound'))} />
          </Switch>
        </animated.div>
      ))}
    </App>
  ) : (
    <QueryParamProvider ReactRouterRoute={Route}>
      <Switch>
        {map(modules, ({ path, withPatient, ...module }) =>
          createModules({
            path: `${path}/:moduleHash${withPatient ? '/:patientId' : ''}`,
            ...module,
          }),
        )}
      </Switch>
    </QueryParamProvider>
  );
};

render(
  <Provider store={createdStore}>
    <ConnectedRouter history={history}>
      <Global styles={globalStyles} />
      <AppSwitch />
    </ConnectedRouter>
  </Provider>,
  document.getElementById('root'),
);
registerServiceWorker();
