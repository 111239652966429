import '@ant-design/compatible/assets/index.css';

import { CameraOutlined, EuroOutlined, InfoCircleOutlined } from '@ant-design/icons';
import styled from '@emotion/styled';
import { Form, Input, InputNumber, Switch, Tooltip } from 'antd';
import GenericModal from 'components/Modal/Generic';
import { STATUS_OK, TYPE_QUESTION } from 'components/Modal/Types';
import config from 'config';
import { colors } from 'global/variables';
import PropTypes from 'helpers/PropTypes';
import translations from 'locales/main.yaml';
import { find, head, isEmpty, isUndefined, keys, noop, size, values } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

const {
  prices: { min, max },
} = config;

const { Item, useForm } = Form;
const {
  global: { currency: currencyTrans },
  prices: {
    samePrice,
    samePriceDetails,
    modal: {
      description,
      fields: {
        name: nameTrans,
        namePlaceholder,
        minPrice: minPriceTrans,
        maxPrice: maxPriceTrans,
        isBracesPrice: isBracesPriceTrans,
      },
      validation: { name: nameValTrans, price: priceValTrans },
    },
    rootWarning,
  },
} = translations;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 12 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 12 },
  },
};
const { secondaryColor } = colors;

const StyledItem = styled(Item)`
  .ant-form-item-control-wrapper {
    display: flex;
  }
`;

const StyledIcon = styled(InfoCircleOutlined)`
  margin: 0 0.5rem;
`;

// TO DO: make component
const StyledWarning = styled.p`
  color: ${secondaryColor};
  font-style: italic;
  margin: 0 0.5rem;
  text-align: center;
`;

const emptyNode = {
  name: '',
  minPrice: 0,
  maxPrice: 0,
  isBracesPrice: false,
  parentId: null,
};

const nameFieldName = 'name';
const maxPriceFieldName = 'maxPrice';
const minPriceFieldName = 'minPrice';
const isBracesFieldName = 'isBracesPrice';
const isSamePriceFieldName = 'isSamePrice';

const Tree = ({
  closeModal,
  data: {
    node: { children, id, name, minPrice, maxPrice, isBracesPrice, parentId, ...node } = emptyNode, // node to edit, or parent node to add a new node
    isEditing,
  },
  onClose,
  status,
  type,
}) => {
  const [initialValues, setInitialValues] = useState({});
  const [isChangedField, setIsChangedField] = useState(false);
  const [isSamePrice, setIsSamePrice] = useState(minPrice === maxPrice);
  const [form] = useForm();
  const { getFieldsError, getFieldValue, getFieldsValue, setFieldsValue } = form;

  useEffect(() => {
    const defaultValues = {};
    defaultValues[nameFieldName] = isEditing ? name : emptyNode.name;
    defaultValues[minPriceFieldName] = isEditing ? minPrice : emptyNode.minPrice;
    defaultValues[maxPriceFieldName] = isEditing ? maxPrice : emptyNode.maxPrice;
    defaultValues[isBracesFieldName] = isEditing ? isBracesPrice : emptyNode.isBracesPrice;
    defaultValues[isSamePriceFieldName] = minPrice === maxPrice;
    setInitialValues(defaultValues);
    setFieldsValue(defaultValues);
  }, [isBracesPrice, isEditing, maxPrice, minPrice, name, setFieldsValue]);

  const onModalClose = useCallback(
    () =>
      onClose({
        children: [],
        id: isEditing ? id : null,
        parentId: isEditing ? parentId : id, // in case adding parentId need to be setup
        ...node,
        ...getFieldsValue(),
      }),
    [id, isEditing, getFieldsValue, node, parentId, onClose],
  );

  const checkName = useCallback(
    (rule, value) => (size(value) >= 3 ? Promise.resolve() : Promise.reject(nameValTrans)),
    [],
  );

  const checkPrice = useCallback(
    (rule, value) =>
      value >= 0 &&
      (isSamePrice || getFieldValue(minPriceFieldName) <= getFieldValue(maxPriceFieldName))
        ? Promise.resolve()
        : Promise.reject(priceValTrans),
    [isSamePrice, getFieldValue],
  );

  const setSamePrice = useCallback(
    (value) => {
      setIsSamePrice(value);
    },
    [setIsSamePrice],
  );

  const onMinPriceChange = useCallback(
    (value) => isSamePrice && setFieldsValue({ [maxPriceFieldName]: value }),
    [isSamePrice, setFieldsValue],
  );

  const onFormChange = useCallback(
    (changedFields) => {
      const key = head(keys(changedFields));
      const value = head(values(changedFields));
      setIsChangedField(initialValues[key] !== value);
    },
    [initialValues, setIsChangedField],
  );

  const isFormValid = useCallback(
    () => isUndefined(find(getFieldsError(), ({ errors }) => !isEmpty(errors))),
    [getFieldsError],
  );

  const isParent = useMemo(
    () => parentId === null || (isEditing && size(children) > 0),
    [children, isEditing, parentId],
  );

  return (
    <GenericModal
      disabled={!isChangedField || !isFormValid()}
      closeModal={closeModal}
      onClose={onModalClose}
      status={status}
      type={type}
      withStatusIcon={false}
    >
      <p>{description}</p>
      {parentId === null && <StyledWarning>{rootWarning}</StyledWarning>}
      <Form form={form} onValuesChange={onFormChange} {...formItemLayout}>
        <StyledItem
          label={nameTrans}
          colon={false}
          name={nameFieldName}
          rules={[{ required: true, validator: checkName }]}
          hasFeedback
        >
          <Input placeholder={namePlaceholder} />
        </StyledItem>
        <StyledItem
          label={minPriceTrans}
          colon={false}
          name={minPriceFieldName}
          rules={[
            {
              required: !isParent,
              validator: checkPrice,
            },
          ]}
          hasFeedback={!isParent}
        >
          <InputNumber
            name={minPriceFieldName}
            disabled={isParent}
            min={min}
            max={max}
            onChange={onMinPriceChange}
            formatter={(value) => `${value}${currencyTrans}`}
            parser={(value) =>
              value.replace(
                new RegExp(`${currencyTrans}|${currencyTrans[0]}|${currencyTrans[1]}`, 'g'),
                '',
              )
            }
          />
        </StyledItem>
        <StyledItem
          label={maxPriceTrans}
          name={maxPriceFieldName}
          colon={false}
          rules={[
            {
              required: !isParent,
              validator: checkPrice,
            },
          ]}
          hasFeedback={!isParent}
        >
          <InputNumber
            disabled={isParent || isSamePrice}
            min={0}
            max={10000}
            formatter={(value) => `${value}${currencyTrans}`}
            parser={(value) =>
              value.replace(
                new RegExp(`${currencyTrans}|${currencyTrans[0]}|${currencyTrans[1]}`, 'g'),
                '',
              )
            }
          />
        </StyledItem>
        <StyledItem
          label={
            <span>
              {samePrice}
              <Tooltip placement="top" title={samePriceDetails}>
                <StyledIcon />
              </Tooltip>
            </span>
          }
          name={isSamePriceFieldName}
          colon={false}
          valuePropName="checked"
        >
          <Switch checkedChildren={<EuroOutlined />} disabled={isParent} onChange={setSamePrice} />
        </StyledItem>
        <StyledItem
          label={isBracesPriceTrans}
          colon={false}
          name={isBracesFieldName}
          valuePropName="checked"
        >
          <Switch disabled={isParent} checkedChildren={<CameraOutlined />} />
        </StyledItem>
      </Form>
    </GenericModal>
  );
};

Tree.propTypes = {
  closeModal: PropTypes.func,
  data: PropTypes.object,
  onClose: PropTypes.func,
  status: PropTypes.number,
  type: PropTypes.string,
};

Tree.defaultProps = {
  closeModal: noop,
  data: { node: emptyNode },
  onClose: noop,
  status: STATUS_OK,
  type: TYPE_QUESTION,
};

export default Tree;
