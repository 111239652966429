import axios from 'axios';
import config from 'config';
import { getData, getError, isLoading } from 'helpers/resources';
import { filter, isEmpty, noop } from 'lodash';
import {
  CEPHALOMETRY_RESOURCE,
  DELETE_CEPHALOMETRY,
  FETCH_CEPHALOMETRIES,
} from 'reducers/cephalometry';
import { SET_SESSION } from 'reducers/login';
import {
  DELETE_STL_MODEL,
  DELETE_UPLOADING_MODEL,
  FETCH_STL_MODELS,
  MODELS_RESOURCE,
  POST_STL_MODEL,
  SET_UPLOADING_MODEL,
} from 'reducers/models';
import { FETCH_PATIENT, PATIENTS_RESOURCE } from 'reducers/patients';
import { FETCH_PATIENT_PHOTOS, PATIENTS_PHOTOS_RESOURCE } from 'reducers/photos';
import { FETCH_PRICES, GET_USED_PRICES_COUNT, PRICES_RESOURCE } from 'reducers/prices';
import {
  CREATE_REFFERRAL,
  DELETE_REFFERRAL,
  FETCH_REFFERRALS,
  REFFERRALS_RESOURCE,
  UPDATE_REFFERRAL,
} from 'reducers/referrals';
import {
  CLEAR_REPORTS,
  CONFIRM_REPORT,
  CREATE_REPORT,
  DELETE_REPORT,
  FETCH_REPORTS,
  REPORTS_RESOURCE,
} from 'reducers/reports';
import {
  CREATE_SCHEMAS,
  DELETE_SCHEMAS,
  FETCH_SCHEMAS,
  SAVE_PRICES,
  SAVE_SCHEMAS,
  SCHEMAS_RESOURCE,
} from 'reducers/schemas';
import { GET_DONE_NFZ_PROCEDURES, GET_USED_NFZ_POINTS } from 'reducers/statistic';

const {
  api: {
    cephalometry,
    doneNfzProcedures,
    host,
    patient,
    photo,
    photoGroupedBy,
    prices,
    pricesCreate,
    schemas,
    schemasCreate,
    schemasUpdate,
    schemasDelete,
    treatmentPrices,
    referrals,
    reports,
    monthlyReports,
    statistic,
    usedNfzPoints,
    stlModel,
  },
} = config;

export const fetchPrices = (version) => ({
  type: FETCH_PRICES,
  payload: axios.get(`${host}${prices}`, version && { params: { version } }),
});

export const getPrices = (state) => getData(state, [PRICES_RESOURCE]);
export const isPricesLoading = (state) => isLoading(state, [PRICES_RESOURCE]);

export const fetchSchemas = () => ({
  type: FETCH_SCHEMAS,
  payload: axios.get(`${host}${schemas}`),
});

export const createSchema = (data) => ({
  type: CREATE_SCHEMAS,
  payload: axios.post(`${host}${schemasCreate}`, data),
});

export const saveSchema = (data) => ({
  type: SAVE_SCHEMAS,
  payload: axios.put(`${host}${schemasUpdate}`, data),
});

export const deleteSchema = (id) => ({
  type: DELETE_SCHEMAS,
  payload: axios.delete(`${host}${schemasDelete}`, { params: { id } }),
});

export const fetchReferrals = () => ({
  type: FETCH_REFFERRALS,
  payload: axios.get(`${host}${referrals}`),
});

export const createReferral = (data) => ({
  type: CREATE_REFFERRAL,
  payload: axios.post(`${host}${referrals}`, data),
});

export const updateReferral = ({ id, ...dataRest }) => ({
  type: UPDATE_REFFERRAL,
  payload: axios.put(`${host}${referrals}/${id}`, dataRest),
});

export const deleteReferral = (id) => ({
  type: DELETE_REFFERRAL,
  payload: axios.delete(`${host}${referrals}/${id}`),
});

export const getReferrals = (state) => getData(state, [REFFERRALS_RESOURCE]);

export const fetchReports = () => ({
  type: FETCH_REPORTS,
  payload: axios.get(`${host}${reports}`),
});

export const getPatient = (state) => getData(state, [PATIENTS_RESOURCE]);

export const fetchPatient = (id) => ({
  type: FETCH_PATIENT,
  payload: axios.get(`${host}${patient}/${id}`),
});

export const fetchPatientPhotos = (patientId, groupBy) => ({
  type: FETCH_PATIENT_PHOTOS,
  payload: axios.get(
    `${host}${photo}${groupBy ? photoGroupedBy : ''}?${new URLSearchParams({
      patientId,
      groupBy,
    }).toString()}`,
  ),
});

export const getPatientPhotos = (state) => getData(state, [PATIENTS_PHOTOS_RESOURCE]);

export const getModels = (state) => getData(state, [MODELS_RESOURCE]);

export const fetchModels = (patientId) => ({
  type: FETCH_STL_MODELS,
  payload: axios.get(`${host}${stlModel}?${new URLSearchParams({ patientId }).toString()}`),
});

export const deleteModel = (id) => ({
  type: DELETE_STL_MODEL,
  payload: axios.delete(`${host}${stlModel}/${id}`),
});

export const setUploadingModel = (payload) => ({
  type: SET_UPLOADING_MODEL,
  payload,
});

export const deleteUploadingModel = (payload) => ({
  type: DELETE_UPLOADING_MODEL,
  payload,
});

export const getUploadingModel = (state) => state[MODELS_RESOURCE].uploading;

export const postModel = (file, patientId, title = '', description = '', onProgress = noop) => {
  const formData = new FormData();
  formData.append('file', file);
  const params = new URLSearchParams({
    title,
    description,
    patientId,
  }).toString();
  return {
    type: POST_STL_MODEL,
    payload: axios.post(`${host}${stlModel}?${params}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress: (progressEvent) => onProgress && onProgress(progressEvent),
    }),
  };
};

export const getCephalometries = (state) => getData(state, [CEPHALOMETRY_RESOURCE]);

export const fetchCephalometries = (patientId) => ({
  type: FETCH_CEPHALOMETRIES,
  payload: axios.get(`${host}${cephalometry}?${new URLSearchParams({ patientId }).toString()}`),
});

export const deleteCephalometry = (id) => ({
  type: DELETE_CEPHALOMETRY,
  payload: axios.delete(`${host}${cephalometry}/${id}`),
});

export const createReport = (
  firstDataAvailabilityRegularCases,
  firstDataAvailabilityUrgentCases,
  secondDataAvailabilityRegularCases,
  secondDataAvailabilityUrgentCases,
) => ({
  type: CREATE_REPORT,
  payload: axios.post(`${host}${reports}`, {
    firstDataAvailabilityRegularCases,
    firstDataAvailabilityUrgentCases,
    secondDataAvailabilityRegularCases,
    secondDataAvailabilityUrgentCases,
  }),
});

export const confirmReport = (id) => ({
  type: CREATE_REPORT,
  payload: axios.put(`${host}${reports}/${id}`, {
    confirm: 'y',
  }),
});

export const deleteReport = (id) => ({
  type: DELETE_REPORT,
  payload: axios.delete(`${host}${reports}/${id}`, {
    confirm: 'y',
  }),
});

export const clearReports = () => ({
  type: CLEAR_REPORTS,
});

export const fetchMonthlyReport = () => ({
  type: FETCH_REPORTS,
  payload: axios.get(`${host}${monthlyReports}`),
});

export const createMonthlyReport = () => ({
  type: CREATE_REPORT,
  payload: axios.post(`${host}${monthlyReports}`),
});

export const confirmMonthlyReport = (id, deleteDraft = false) => ({
  type: CONFIRM_REPORT,
  payload: axios.put(`${host}${monthlyReports}/confirm/${id}`, {
    deleteDraft,
  }),
});

export const deleteMonthlyReport = (id) => ({
  type: DELETE_REPORT,
  payload: axios.delete(`${host}${monthlyReports}/${id}`),
});

export const startSession = (timestamp) => ({
  type: SET_SESSION,
  payload: { isSession: true, timestamp: timestamp || Date.now() },
});

export const stopSession = () => ({
  type: SET_SESSION,
  payload: { isSession: false, timestamp: null },
});

export const savePrice = (data) => ({
  type: SAVE_PRICES,
  payload: axios.post(`${host}${pricesCreate}`, data),
});

export const getUsedPricesCount = (version) => ({
  type: GET_USED_PRICES_COUNT,
  payload: axios.get(`${host}${treatmentPrices}`, { params: { version } }),
});

export const getUsedNfzPoints = (startDate = null, endDate = null) => ({
  type: GET_USED_NFZ_POINTS,
  payload: axios.get(`${host}${statistic}${usedNfzPoints}`, { params: { startDate, endDate } }),
});

export const getDoneNfzProcedure = (startDate = null, endDate = null) => ({
  type: GET_DONE_NFZ_PROCEDURES,
  payload: axios.get(`${host}${statistic}${doneNfzProcedures}`, { params: { startDate, endDate } }),
});
// filter out empty schemas - needed in main application to proper handle select!
export const getSchemas = (state) =>
  filter(getData(state, [SCHEMAS_RESOURCE]), ({ name }) => !isEmpty(name));
export const isSchemasLoading = (state) => isLoading(state, [SCHEMAS_RESOURCE]);

export const getReports = (state) => getData(state, [REPORTS_RESOURCE]);
export const getReportError = (state) => getError(state, [REPORTS_RESOURCE]);
