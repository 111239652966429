import { fulfilled, initialState, pending, rejected } from 'helpers/resources';
import produce from 'immer';
import typeToReducer from 'type-to-reducer';

export const REFFERRALS_RESOURCE = 'referrals';
export const FETCH_REFFERRALS = 'FETCH_REFFERRALS';
export const CREATE_REFFERRAL = 'CREATE_REFFERRAL';
export const DELETE_REFFERRAL = 'DELETE_REFFERRAL';
export const UPDATE_REFFERRAL = 'UPDATE_REFFERRAL';

export default typeToReducer(
  {
    [FETCH_REFFERRALS]: {
      PENDING: (state) => pending(state),
      FULFILLED: (state, payload) => fulfilled(state, payload),
      REJECTED: (state, payload) => rejected(state, payload),
    },
    [CREATE_REFFERRAL]: {
      PENDING: (state) => pending(state),
      FULFILLED: (
        state,
        {
          payload: {
            data: { createdAt, updatedAt, ...restData },
          },
        },
      ) =>
        produce(state, (draft) => {
          draft.data = [...draft.data, restData];
        }),
      REJECTED: (state, payload) => rejected(state, payload),
    },
    [UPDATE_REFFERRAL]: {
      PENDING: (state) => pending(state),
      FULFILLED: (
        state,
        {
          payload: {
            data: { id, ...restData },
          },
        },
      ) =>
        produce(state, (draft) => {
          draft.data = draft.data.map(({ id: dataId, ...rest }) =>
            dataId === id ? { id, ...restData } : { id: dataId, ...rest },
          );
        }),
      REJECTED: (state, payload) => rejected(state, payload),
    },
    [DELETE_REFFERRAL]: {
      PENDING: (state) => pending(state),
      FULFILLED: (
        state,
        {
          payload: {
            data: { id },
          },
        },
      ) =>
        produce(state, (draft) => {
          draft.data = draft.data.filter(({ id: dataId }) => dataId !== id);
        }),
      REJECTED: (state, payload) => rejected(state, payload),
    },
    // [CLEAR_REFFERRALS]: (state) => ({
    //   ...state,
    //   data: [],
    // }),
  },
  initialState(),
);
