export const STATUS_OK = 0;
export const STATUS_WARNING = 1;
export const STATUS_ERROR = 2;

export const TYPE_OK = 'TYPE_OK';
export const TYPE_QUESTION = 'TYPE_QUESTION';
export const TYPE_YES_NO = 'TYPE_YES_NO';

export const MODAL_TEXT = 'TextModal';
export const MODAL_TREE = 'TreeModal';
export const MODAL_STL_MODEL = 'StlModelModal';
export const MODAL_GALLERY_UPLOAD = 'GalleryUploadModal';
export const MODAL_REFERRAL = 'ReferralModal';
