import * as icons from '@ant-design/icons';
import { Menu as AntMenu } from 'antd';
import config from 'config';
import { push } from 'connected-react-router';
import translations from 'locales/main.yaml';
import { includes, last, split, trimStart } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';

const MenuItem = AntMenu.Item;
const { pages } = config;
const {
  global: { pages: pagesTrans },
} = translations;

const Menu = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const pushState = useCallback((path) => dispatch(push(path)), [dispatch]);
  const rootPathname = `/${last(split(pathname, '/', 2))}`; // handle subpages - get main path
  const [key, setKey] = useState(rootPathname);

  useEffect(() => {
    setKey(rootPathname);
  }, [setKey, rootPathname]);

  const handleMenu = ({ key: newKey }) => {
    setKey(newKey);
    pushState(newKey);
  };

  const isMenu = useCallback(
    () =>
      includes(
        pages.filter(({ menu }) => menu).map(({ path }) => path),
        key,
      ),
    [key],
  );

  const items = useMemo(
    () =>
      pages
        .filter(({ menu }) => menu)
        .map(({ enabled, icon, path }) => {
          const Icon = icons[icon];
          return (
            <MenuItem key={path} disabled={!enabled}>
              <Icon />
              <span>{pagesTrans[trimStart(path, '/')]}</span>
            </MenuItem>
          );
        }),
    [],
  );

  return (
    <>
      {isMenu() && (
        <AntMenu onClick={handleMenu} selectedKeys={[key]} mode="horizontal">
          {items}
        </AntMenu>
      )}
    </>
  );
};

export default Menu;
