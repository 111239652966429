import { fulfilled, initialState, pending, rejected } from 'helpers/resources';
import typeToReducer from 'type-to-reducer';

export const PRICES_RESOURCE = 'prices';
export const FETCH_PRICES = 'FETCH_PRICES';
export const GET_USED_PRICES_COUNT = 'GET_USED_PRICES_COUNT';

export default typeToReducer(
  {
    [FETCH_PRICES]: {
      PENDING: (state) => pending(state),
      FULFILLED: (state, { payload: { data } }) =>
        fulfilled(state, {
          payload: { data },
        }),
      REJECTED: (state, payload) => rejected(state, payload),
    },
  },
  initialState(),
);
