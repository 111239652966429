import { fulfilled, initialState, pending, rejected } from 'helpers/resources';
import produce from 'immer';
import { filter } from 'lodash';
import typeToReducer from 'type-to-reducer';

export const CEPHALOMETRY_RESOURCE = 'cephalometry';
export const FETCH_CEPHALOMETRIES = 'FETCH_CEPHALOMETRIES';
export const DELETE_CEPHALOMETRY = 'DELETE_CEPHALOMETRY';

export default typeToReducer(
  {
    [FETCH_CEPHALOMETRIES]: {
      PENDING: (state) => pending(state),
      FULFILLED: (state, { payload: { data } }) =>
        fulfilled(state, {
          payload: { data },
        }),
      REJECTED: (state, payload) => rejected(state, payload),
    },
    [DELETE_CEPHALOMETRY]: {
      FULFILLED: (
        state,
        {
          payload: {
            data: { id },
          },
        },
      ) =>
        produce(state, (draft) => {
          draft.data = filter(draft.data, ({ id: dataId }) => id !== dataId);
        }),
    },
  },
  initialState(),
);
