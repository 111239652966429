/* eslint-disable no-nested-ternary */
import { Form, Input, Select } from 'antd';
import GenericModal from 'components/Modal/Generic';
import PropTypes from 'helpers/PropTypes';
import translations from 'locales/main.yaml';
import { lowerCase, map, noop, upperFirst } from 'lodash';
import React, { useCallback, useEffect } from 'react';

const { Item, useForm } = Form;

const {
  referrals: {
    modal: {
      createHeader: createHeaderTrans,
      editHeader: editHeaderTrans,
      previewHeader: previewHeaderTrans,
      fields: {
        address: addressTrans,
        doctorName: doctorNameTrans,
        firmName: firmNameTrans,
        name: nameTrans,
        openHours: openHoursTrans,
        phone: phoneTrans,
        type: typeTrans,
      },
      errors: { doctorName: doctorNameErrorTrans, name: nameErrorTrans, type: typeErrorTrans },
    },
  },
} = translations;

// if there is a referral in data that means it's edit mode
const Refferal = ({ closeModal, data: { isPreview, referral, types }, onConfirm }) => {
  const [form] = useForm();
  const { getFieldsValue, setFieldsValue, submit } = form;

  useEffect(() => {
    if (referral) {
      setFieldsValue(referral);
    }
  }, [referral]);

  const handleOnConfirm = useCallback(() => {
    // TODO: temp solution to handle validation - refactor Generic modal to handle submit with validation
    submit();

    const { doctorName, name, type, ...rest } = getFieldsValue();
    if (doctorName && name && type) {
      onConfirm({ id: referral?.id, doctorName, name, type: upperFirst(type), ...rest });
      return true;
    }
    return false;
  }, []);

  return (
    <GenericModal
      closeModal={closeModal}
      customTitle={
        referral ? (isPreview ? previewHeaderTrans : editHeaderTrans) : createHeaderTrans
      }
      disabled={isPreview}
      onClose={handleOnConfirm} // TODO: refactor buttons to use 'save'
      withStatusIcon={false}
    >
      <Form form={form}>
        <Item label={typeTrans} name="type" rules={[{ required: true, message: typeErrorTrans }]}>
          <Select
            disabled={isPreview}
            options={map(types, (type) => ({ label: type, value: lowerCase(type) }))}
            onClick={(event) => {
              event.stopPropagation(); // TODO: why modal hides when clicked on Select
            }}
          />
        </Item>
        <Item label={nameTrans} name="name" rules={[{ required: true, message: nameErrorTrans }]}>
          <Input disabled={isPreview} />
        </Item>
        <Item
          label={doctorNameTrans}
          name="doctorName"
          rules={[{ required: true, message: doctorNameErrorTrans }]}
        >
          <Input disabled={isPreview} />
        </Item>
        <Item label={firmNameTrans} name="firmName">
          <Input disabled={isPreview} />
        </Item>
        <Item label={addressTrans} name="address">
          <Input disabled={isPreview} />
        </Item>
        <Item label={phoneTrans} name="phone">
          <Input disabled={isPreview} />
        </Item>
        <Item label={openHoursTrans} name="openHours">
          <Input disabled={isPreview} />
        </Item>
      </Form>
    </GenericModal>
  );
};

Refferal.propTypes = {
  closeModal: PropTypes.func,
  data: PropTypes.object,
  onConfirm: PropTypes.func,
};

Refferal.defaultProps = {
  closeModal: noop,
  data: {},
  onConfirm: noop,
};

export default Refferal;
