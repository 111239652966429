import { fulfilled, initialState, pending, rejected } from 'helpers/resources';
import typeToReducer from 'type-to-reducer';

export const PATIENTS_PHOTOS_RESOURCE = 'photos';
export const FETCH_PATIENT_PHOTOS = 'GET_PATIENT_PHOTOS';

export default typeToReducer(
  {
    [FETCH_PATIENT_PHOTOS]: {
      PENDING: (state) => pending(state),
      FULFILLED: (state, { payload: { data } }) =>
        fulfilled(state, {
          payload: { data },
        }),
      REJECTED: (state, payload) => rejected(state, payload),
    },
  },
  initialState(),
);
