import produce from 'immer';

export const SET_SESSION = 'SET_SESSION';

const initialState = {
  isSession: false,
  timestamp: null,
};

export default (state = initialState, { type, payload: { isSession, timestamp } = {} }) => {
  switch (type) {
    case SET_SESSION:
      return produce(state, (draft) => {
        draft.isSession = isSession;
        draft.timestamp = timestamp;
      });
    default:
      return state;
  }
};
