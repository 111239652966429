import { LeftCircleOutlined } from '@ant-design/icons';
import styled from '@emotion/styled';
import { goBack } from 'connected-react-router';
import { colors, media } from 'global/variables';
import PropTypes from 'helpers/PropTypes';
import translations from 'locales/main.yaml';
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import borderBottom from 'static/border-bottom.png';
import borderTop from 'static/border-top.png';

const {
  global: { back },
} = translations;
const { borderColor, primaryColor } = colors;
const {
  xl,
  css: { xs },
} = media;

const StyledContainer = styled.div`
  border: 1px solid ${borderColor};
  padding: 3rem 1rem;
  position: relative;
  margin: 4rem 0;
  width: 100%;
  max-width: ${xl}px;

  ${xs} {
    margin: 2rem 0;
  }
`;

const borderStyles = `
  position: absolute;
  left: calc(50% - 2rem);
  width: 3.75rem;
  height: 3.75rem;
`;

const StyledBorderTop = styled.div`
  background: url(${borderTop}) center bottom no-repeat;
  top: -1.9rem;
  ${borderStyles}
`;

const StyledBorderBottom = styled.div`
  background: url(${borderBottom}) center bottom no-repeat;
  bottom: -1.9rem;
  ${borderStyles}
`;

const StyledIcon = styled(LeftCircleOutlined)`
  color: ${borderColor};
  font-size: 1.5rem;
  transition: font-size 0.5s;
`;

const CenteredIconContainer = styled.div`
  position: absolute;
  top: 0.5rem;
  left: 0.5rem;
  display: flex;
  align-items: center;
  cursor: pointer;
  
  &:hover {
    span {
      color: ${primaryColor};
    }
    span:first-child {
      font-size: 2rem;
    }
  },
`;

const StyledSpan = styled.span`
  color: ${borderColor};
  margin: 0 0.75rem 0 0.25rem;
  text-transform: uppercase;
`;

// TODO: put spin here and add property withSpin
const Container = ({ children, className, withBack }) => {
  const dispatch = useDispatch();
  const handleGoBack = useCallback(() => dispatch(goBack()), [dispatch]);

  return (
    <StyledContainer className={className}>
      {withBack && (
        <CenteredIconContainer>
          <StyledIcon onClick={handleGoBack} />
          <StyledSpan>{back}</StyledSpan>
        </CenteredIconContainer>
      )}
      <StyledBorderTop />
      <StyledBorderBottom />
      {children}
    </StyledContainer>
  );
};

Container.propTypes = {
  children: PropTypes.children,
  className: PropTypes.string,
  withBack: PropTypes.bool,
};

Container.defaultProps = {
  children: null,
  className: '',
  withBack: false,
};

export default Container;
