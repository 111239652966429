import config from 'config';
import {
  getStoragePassword,
  getStorageTimestamp,
  getStorageUsername,
  resetStorage,
  setStoragePassword,
  setStorageTimestamp,
  setStorageUsername,
} from 'helpers/storage';
import { find, get, includes, keys, map } from 'lodash';
import md5 from 'md5';

const {
  login: { moduleHash, timeout, users },
  modules,
} = config;

const modulesPaths = map(modules, ({ path }) => path);
const isUser = (user) => includes(keys(users), user);
const getPassword = (user) => get(users, [user, 'password']);

export const getAvatar = () => get(users, [getStorageUsername(), 'avatar']);
export const getName = () => get(users, [getStorageUsername(), 'name']);
export const verify = (verUsername, verPassword) => {
  const md5Pass = md5(verPassword);
  const result = isUser(verUsername) && getPassword(verUsername) === md5Pass;

  if (result) {
    setStoragePassword(md5Pass);
    setStorageTimestamp();
    setStorageUsername(verUsername);
  }
  return result;
};
export const isSession = (timestamp) => Date.now() - timestamp < timeout;
export const isStorageSession = () =>
  isUser(getStorageUsername()) &&
  getStoragePassword() === getPassword(getStorageUsername()) &&
  isSession(getStorageTimestamp());
export const logout = () => resetStorage();
export const isModule = (path) => !!find(modulesPaths, (modulePath) => includes(path, modulePath));
export const isHash = (hash) => hash === moduleHash;
