import { connectRouter } from 'connected-react-router';
import { combineReducers } from 'redux';

import cephalometry from './cephalometry';
import login from './login';
import models from './models';
import patients from './patients';
import photos from './photos';
import prices from './prices';
import referrals from './referrals';
import reports from './reports';
import schemas from './schemas';

export default (history) =>
  combineReducers({
    cephalometry,
    login,
    models,
    patients,
    photos,
    prices,
    referrals,
    reports,
    schemas,
    router: connectRouter(history),
  });
