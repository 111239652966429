/* eslint-disable */
import styled from '@emotion/styled';
import { Switch } from 'antd';
import PropTypes from 'helpers/PropTypes';
import { findAllRootsInTree } from 'helpers/tree';
import translations from 'locales/main.yaml';
import { map } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

const {
  global: {
    tree: { expand, fold },
  },
} = translations;

const StyledSwitchSingle = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 0.5rem 0;
`;

const withExpander =
  (Component) =>
  ({ data, expandedKeys: treeExpandedKeys, ...props }) => {
    const [isExpanded, setIsExpanded] = useState(true);
    const [expandedKeys, setExpandedKeys] = useState([]);

    const defaultExpandedKeys = useMemo(
      () => map(findAllRootsInTree(data), (id) => `${id}`),
      [data],
    );

    useEffect(() => {
      // treeExpandedKeys - expanded element provided by tree itself for instance root of checked
      // elements in schemas
      setExpandedKeys(treeExpandedKeys || defaultExpandedKeys);
    }, [defaultExpandedKeys, treeExpandedKeys]);

    const expandTree = useCallback(() => {
      setExpandedKeys(isExpanded ? [] : defaultExpandedKeys);
      setIsExpanded(!isExpanded);
    }, [defaultExpandedKeys, isExpanded, setExpandedKeys, setIsExpanded]);

    const onExpand = useCallback(
      (currentExpandedKeys) => setExpandedKeys(currentExpandedKeys),
      [setExpandedKeys],
    );

    return (
      <>
        <StyledSwitchSingle>
          <Switch checkedChildren={expand} unCheckedChildren={fold} onClick={expandTree} />
        </StyledSwitchSingle>
        <Component data={data} onExpand={onExpand} expandedKeys={expandedKeys} {...props} />
      </>
    );
  };

withExpander.propTypes = {
  data: PropTypes.array,
  expandedKeys: PropTypes.array,
};

withExpander.defaultProps = {
  data: [],
  expandedKeys: [],
};

export default withExpander;
