import { fulfilled, initialState, pending, rejected } from 'helpers/resources';
import typeToReducer from 'type-to-reducer';

export const REPORTS_RESOURCE = 'reports';
export const FETCH_REPORTS = 'FETCH_REPORTS';
export const CREATE_REPORT = 'CREATE_REPORT';
export const DELETE_REPORT = 'DELETE_REPORT';
export const CONFIRM_REPORT = 'CONFIRM_REPORT';
export const CLEAR_REPORTS = 'CLEAR_REPORTS';

export default typeToReducer(
  {
    [FETCH_REPORTS]: {
      PENDING: (state) => pending(state),
      FULFILLED: (state, payload) => fulfilled(state, payload),
      REJECTED: (state, payload) => rejected(state, payload),
    },
    [CREATE_REPORT]: {
      PENDING: (state) => pending(state),
      REJECTED: (state, payload) => rejected(state, payload),
    },
    [CLEAR_REPORTS]: (state) => ({
      ...state,
      data: [],
    }),
  },
  initialState(),
);
