/* eslint-disable no-nested-ternary */

import '@ant-design/compatible/assets/index.css';

import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { keyframes } from '@emotion/core';
import styled from '@emotion/styled';
import { startSession } from 'actions';
import { Button, Col, Form, Input, Row } from 'antd';
import Container from 'components/Container';
import config from 'config';
import { push } from 'connected-react-router';
import { colors } from 'global/variables';
import { isStorageSession, verify } from 'helpers/login';
import translations from 'locales/main.yaml';
import { isEmpty, keys, noop } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

const {
  pages: [{ path }],
} = config;
const {
  login: {
    submit: submitTrans,
    password: passwordTrans,
    username: usernameTrans,
    errors: { form: errFormTrans, password: errPasswordTrans, username: errUsernameTrans },
  },
} = translations;
const { borderColor, red } = colors;
const { Item, useForm } = Form;

const bounce = keyframes`
  from, 20%, 53%, 80%, to {
    transform: translate3d(0,0,0);
  }

  40%, 43% {
    transform: translate3d(-30px, 0, 0);
  }

  70% {
    transform: translate3d(-15px, 0, 0);
  }

  90% {
    transform: translate3d(-4px, 0, 0);
  }
`;

const StyledItem = styled(Item)`
  max-width: 12rem;
  margin: 0.25rem auto;

  .ant-form-item-control.has-error {
    input,
    svg {
      animation: ${bounce} 1s ease;
    }
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 0.5rem;
`;

const StyledForm = styled(Form)`
  text-align: center;
`;

const StyledSpan = styled.span`
  color: ${red};
`;

const Login = () => {
  const dispatch = useDispatch();
  const pushState = useCallback(() => dispatch(push(path)), [dispatch]);
  const handleStartSession = useCallback(() => dispatch(startSession()), [dispatch]);
  const [isInvalid, setIsInvalid] = useState(false);
  const [usernameStatus, setUsernameStatus] = useState('');
  const [passwordStatus, setPasswordStatus] = useState('');
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [form] = useForm();
  const { getFieldsError, getFieldError } = form;

  useEffect(() => {
    if (isStorageSession()) {
      pushState();
    }
  }, [pushState]);

  const hasErrors = useCallback(() => {
    const fieldsError = getFieldsError();
    keys(fieldsError).some((field) => fieldsError[field]);
  }, [getFieldsError]);

  const hasFieldError = useCallback(
    (fieldName) => !isEmpty(getFieldError(fieldName)),
    [getFieldError],
  );

  useEffect(() => {
    setUsernameStatus(isInvalid || hasFieldError('username') ? 'error' : '');
    setPasswordStatus(isInvalid || hasFieldError('password') ? 'error' : '');
    setIsButtonDisabled(isInvalid || hasErrors());
  }, [hasErrors, hasFieldError, form, isInvalid]);

  const onChange = useCallback(
    () => (isInvalid ? setIsInvalid(false) : noop),
    [isInvalid, setIsInvalid],
  );

  const handleFinish = useCallback(
    ({ username, password }) => {
      const result = verify(username, password);
      if (result) {
        handleStartSession();
        pushState();
      } else {
        setIsInvalid(true);
      }
    },
    [handleStartSession, pushState, setIsInvalid],
  );

  return (
    <Container>
      <Row type="flex" justify="center">
        <Col span={8}>
          <StyledForm form={form} onFinish={handleFinish}>
            <StyledItem
              name="username"
              rules={[{ required: true, message: errUsernameTrans }]}
              validateStatus={usernameStatus}
            >
              <Input
                prefix={<UserOutlined style={{ color: borderColor }} />}
                placeholder={usernameTrans}
                onChange={onChange}
              />
            </StyledItem>
            <StyledItem
              name="password"
              rules={[{ required: true, message: errPasswordTrans }]}
              validateStatus={passwordStatus}
            >
              <Input
                prefix={<LockOutlined style={{ color: borderColor }} />}
                type="password"
                placeholder={passwordTrans}
                onChange={onChange}
              />
            </StyledItem>
            <ButtonWrapper>
              <Button size="large" type="primary" htmlType="submit" disabled={isButtonDisabled}>
                {submitTrans}
              </Button>
            </ButtonWrapper>
            {isInvalid && <StyledSpan>{errFormTrans}</StyledSpan>}
          </StyledForm>
        </Col>
      </Row>
    </Container>
  );
};

export default Login;
