import { LogoutOutlined } from '@ant-design/icons';
import { keyframes } from '@emotion/core';
import styled from '@emotion/styled';
import { startSession, stopSession } from 'actions';
import { Avatar, Col, Row } from 'antd';
import Menu from 'components/Menu';
import config from 'config';
import { push } from 'connected-react-router';
import { colors, media } from 'global/variables';
import {
  getAvatar,
  getName,
  isStorageSession as storageIsSession,
  logout as storageLogout,
} from 'helpers/login';
import PropTypes from 'helpers/PropTypes';
import { getStorageTimestamp } from 'helpers/storage';
import { withModal } from 'hoc';
import translations from 'locales/main.yaml';
import { last, noop, template } from 'lodash';
import moment from 'moment';
import React, { useCallback, useEffect } from 'react';
import Countdown from 'react-countdown-now';
import { useDispatch, useSelector } from 'react-redux';
import logo from 'static/logo.png';

const {
  global: { copyright, logout: logoutTrans, title, version },
  login: { timeout: timeoutTrans },
} = translations;
const { borderColor, primaryColor, red, white } = colors;
const {
  css: { xs },
} = media;
const {
  isDevelopment,
  pages,
  login: { timeout, timeoutWarning },
} = config;

const blink = keyframes`
  from, 20%, 53%, 80%, to {
    transform: translate3d(0,0,0);
    color: ${white};
  }

  40%, 43% {
    transform: translate3d(0, -20px, 0);
    color: ${red};
  }

  70% {
    transform: translate3d(0, -10px, 0);
    color: ${red};
  }

  90% {
    transform: translate3d(0,-4px,0);
    color: ${white};
  }
`;

const StyledApp = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  margin: 0 auto;
  overflow-x: hidden;
  overflow-y: auto;
`;

const StyledPageContainer = styled(Col)`
  margin: 2rem 0;
  ${xs} {
    margin: 1rem 0;
  }
`;

const StyledHeader = styled(Col)({
  background: primaryColor,

  '> div': {
    boxShadow: '0 8px 6px -6px rgba(0,0,0,0.5)',
    height: '5rem',
    margin: '0 0.5rem 0 0.5rem',
    background: `url(${logo}) no-repeat left center`,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },

  '> span': {
    position: 'absolute',
    right: '0',
    top: '0',
  },
});

const StyledFooter = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  border-top: 1px solid ${borderColor};
  padding: 0.5rem;
`;

const StyledSpan = styled.span(
  {
    color: white,
    fontSize: '1.25rem',
    fontWeight: 'bold',
    margin: '0 1rem',
  },
  ({ isWarning }) => ({
    animation: isWarning && `${blink} 1s ease infinite`,
  }),
);

const StyledIcon = styled(LogoutOutlined)`
  font-size: 2rem;
  color: ${white};
  transition: font-size 0.5s;
`;

const CenteredIconContainer = styled.div`
  display: flex;
  align-items: center;
`;

const CenteredLogoutIconContainer = styled(CenteredIconContainer)`
  cursor: pointer;

  &:hover span:first-child{
    font-size: 2.5rem;
  },
`;

const StyledText = styled.span`
  margin: 0 0.75rem 0 0.25rem;
  color: ${white};
  text-transform: uppercase;
`;

const StyledBold = styled(StyledText)`
  font-weight: bold;
`;

// TO DO: header and footer as components
const App = ({ children, openModal }) => {
  const { path: loginPath } = last(pages);
  const dispatch = useDispatch();
  const pushState = useCallback(() => dispatch(push(loginPath)), [dispatch, loginPath]);
  const handleLogin = useCallback(() => dispatch(startSession(getStorageTimestamp())), [dispatch]);
  const handleLogout = useCallback(() => dispatch(stopSession()), [dispatch]);
  const { isSession, timestamp } = useSelector(({ login }) => login);

  useEffect(() => {
    const fn = storageIsSession() ? handleLogin : storageLogout;
    fn();
  }, [handleLogin]);

  const logout = useCallback(() => {
    storageLogout();
    handleLogout();
    pushState();
  }, [handleLogout, pushState]);

  // eslint-disable-next-line react/prop-types
  const renderer = ({ minutes, seconds }) => (
    <StyledSpan isWarning={minutes < timeoutWarning}>
      {minutes}:{seconds < 10 && 0}
      {seconds}
    </StyledSpan>
  );

  const onComplete = useCallback(() => {
    openModal({ data: { text: timeoutTrans } });
    logout();
  }, [logout, openModal]);

  return (
    <StyledApp id="ortDashApp">
      <Row type="flex" justify="center">
        <StyledHeader span={24}>
          <div>
            {isSession && (
              <>
                <Countdown renderer={renderer} date={timestamp + timeout} onComplete={onComplete} />
                <CenteredIconContainer>
                  <Avatar src={getAvatar()} size="large" />
                  <StyledBold>{getName()}</StyledBold>
                </CenteredIconContainer>
                <CenteredLogoutIconContainer onClick={logout}>
                  <StyledIcon type="logout" />
                  <StyledText>{logoutTrans}</StyledText>
                </CenteredLogoutIconContainer>
              </>
            )}
          </div>
        </StyledHeader>
        <StyledPageContainer span={20}>
          <Menu />
          {children}
        </StyledPageContainer>
      </Row>
      <StyledFooter>
        <span>{template(copyright)({ year: moment().year() })}</span>
        <span>
          {title}{' '}
          {template(version)({
            version: isDevelopment ? process.env.DEV_VERSION : process.env.VERSION,
          })}
        </span>
      </StyledFooter>
    </StyledApp>
  );
};

App.propTypes = {
  children: PropTypes.children,
  openModal: PropTypes.func,
};

App.defaultProps = {
  children: null,
  openModal: noop,
};

export default withModal(App);
