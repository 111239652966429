import {
  cloneDeep,
  compact,
  concat,
  findIndex,
  flattenDeep,
  forEach,
  head,
  map,
  merge,
  nth,
  remove as arrayRemove,
  set,
  size,
  sortBy,
} from 'lodash';

let pathIds = [];

const findPath = ({ id, children }, value) => {
  if (id === value) {
    return true;
  }
  pathIds.push(id);

  let found = false;
  if (size(children)) {
    let i = 0;
    for (; i < size(children); i += 1) {
      found = findPath(nth(children, i), value);
      if (found) {
        break;
      }
    }
  }
  if (!found) {
    pathIds.pop();
  }
  return found;
};

const search = ({ id, children, ...node }, value) => {
  if (id === value) {
    return { id, children, ...node };
  }

  let found = null;
  if (size(children)) {
    let i = 0;
    for (; i < size(children); i += 1) {
      found = search(nth(children, i), value);
      if (found) {
        break;
      }
    }
  }

  return found;
};

const update = (root, node) => {
  const { id, children } = root;
  const { id: nodeId } = node;
  if (id === nodeId) {
    return merge(root, node);
  }

  let found = null;
  if (size(children)) {
    let i = 0;
    for (; i < size(children); i += 1) {
      found = update(nth(children, i), node);
      if (found) {
        found = null;
        break;
      }
    }
  }
  return found;
};

const add = (root, node) => {
  const { id, children, parentId } = root;
  const { parentId: newNodeParentId } = node;

  if (id === newNodeParentId) {
    // root element
    if (parentId === 0) {
      set(root, 'children', concat(children, node));
    }
    return true;
  }

  let found = false;
  if (size(children)) {
    let i = 0;
    for (; i < size(children); i += 1) {
      const child = nth(children, i);
      found = add(nth(children, i), node);
      if (found) {
        set(child, 'children', concat(child.children, node));
        set(child, 'minPrice', null);
        set(child, 'maxPrice', null);
        set(child, 'isBracesPrice', false);
        found = false;
        break;
      }
    }
  }
  return found;
};

const remove = (root, status) => {
  const { children, status: nodeStatus } = root;

  if (nodeStatus === status) {
    return root;
  }

  let removed = null;
  if (size(children)) {
    let i = 0;
    for (; i < size(children); i += 1) {
      removed = remove(nth(children, i), status);
      if (removed) {
        children[i] = null;
        set(root, 'children', compact(children));
      }
    }
  }
  return removed;
};

const move = (tree, id, direction, status, upDirection) => {
  const treeCopy = cloneDeep(sortBy(tree, ['order']));
  const childIndex = findIndex(treeCopy, ({ id: childId }) => childId === id);

  if (childIndex > -1 && childIndex < size(treeCopy)) {
    const swapIndex = direction === upDirection ? childIndex - 1 : childIndex + 1;
    const { order: childOrder } = treeCopy[childIndex];
    const { order: swapOrder } = treeCopy[swapIndex];

    [treeCopy[swapIndex], treeCopy[childIndex]] = [
      { ...treeCopy[childIndex], status, order: swapOrder },
      { ...treeCopy[swapIndex], status, order: childOrder },
    ];
  }
  return treeCopy;
};

const findRoot = (tree) => {
  const { id, children } = tree;

  if (size(children)) {
    const ids = map(children, (child) => findRoot(child));
    return concat(ids, id);
  }
  return null;
};

export const findTreePath = (tree, id) => {
  pathIds = [];
  findPath(tree, id);
  return pathIds;
};

export const searchTree = (tree = [], id) =>
  head(compact(flattenDeep(map(tree, (root) => search(root, id))))) || {};

export const updateTree = (tree = [], node) => {
  const cloneTree = cloneDeep(tree);

  const { parentId } = node;
  if (parentId) {
    forEach(cloneTree, (root) => update(root, node));
  } else {
    const { id: modifiedNodeId } = node;

    // edit root in tree
    const index = findIndex(cloneTree, ({ id }) => id === modifiedNodeId);
    if (index > -1) {
      const modifiedNode = cloneTree[index];
      cloneTree[index] = merge(modifiedNode, node);
    }
  }
  return cloneTree;
};

export const addToTree = (tree = [], node = {}) => {
  const cloneTree = cloneDeep(tree);
  const { parentId } = node;

  if (parentId) {
    // add child to existing root in tree
    forEach(cloneTree, (root) => {
      const isAdded = add(root, node);
      return !isAdded;
    });
  } else {
    // add new root to tree
    cloneTree.push({ parentId: 0, ...node });
  }
  return cloneTree;
};

export const removeFromTree = (tree = [], status) => {
  const cloneTree = cloneDeep(tree);
  // remove roots in tree
  arrayRemove(cloneTree, ({ status: nodeStatus }) => nodeStatus === status);
  // remove children in roots
  forEach(cloneTree, (root) => remove(root, status));

  return cloneTree;
};

export const moveInTree = (tree = [], node, direction, status, upDirection) => {
  const { id, parentId } = node;

  if (parentId) {
    const parentNode = searchTree(tree, parentId);
    const { children } = parentNode;
    return updateTree(
      tree,
      set(parentNode, 'children', move(children, id, direction, status, upDirection)),
    );
  }

  return move(tree, id, direction, status, upDirection);
};

export const findAllRootsInTree = (tree = []) =>
  compact(flattenDeep(map(tree, (root) => findRoot(root))));
