import { InboxOutlined } from '@ant-design/icons';
import styled from '@emotion/styled';
import {
  deleteUploadingModel,
  getModels,
  getPatient,
  getUploadingModel,
  postModel,
  setUploadingModel,
} from 'actions';
import { Form, Input, message, notification, Upload } from 'antd';
import Generic from 'components/Modal/Generic';
import { STATUS_OK, TYPE_OK } from 'components/Modal/Types';
import PropTypes from 'helpers/PropTypes';
import translations from 'locales/main.yaml';
import { deburr, endsWith, noop, size, snakeCase, upperCase } from 'lodash';
import moment from 'moment';
import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const { Item, useForm } = Form;
const { Dragger, LIST_IGNORE } = Upload;

// TODO: use common with Tree modal
const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 18 },
  },
};

const draggerProps = {
  name: 'file',
  maxCount: 1,
  multiple: false,
  fileType: 'stl',
};

const StyledForm = styled(Form)`
  .ant-upload-list {
    margin-bottom: 24px;
  }
  span.anticon-paper-clip {
    left: 25px;
  }
`;

const StyledDragger = styled(Dragger)`
  pointer-events: ${({ isDisabled }) => (isDisabled === true ? 'none' : 'auto')};
  cursor: ${({ isDisabled }) => (isDisabled === true ? 'not-allowed' : 'auto')};
`;

const StlModel = ({ closeModal, data: { patientId }, onConfirm, status, type }) => {
  const [file, setFile] = useState(null);
  const [isTitle, setIsTitle] = useState(true);
  const {
    modules: {
      models: {
        add: addTrans,
        description: descriptionTrans,
        name: nameTrans,
        pick: pickTrans,
        errors: { format: formatErrorTrans, name: nameErrorTrans },
      },
    },
  } = useMemo(() => translations, []);
  const [form] = useForm();
  const { firstName, lastName } = useSelector((state) => getPatient(state));
  const modelsAmount = size(useSelector((state) => getModels(state)));
  const uploadingAmount = size(useSelector((state) => getUploadingModel(state)));
  const dispatch = useDispatch();
  const { getFieldValue } = form;

  const handleBeforeUpload = useCallback(
    (uploadedFile) => {
      const { fileType } = draggerProps;
      const { name } = uploadedFile;
      if (endsWith(name, `.${fileType}`) || endsWith(name, upperCase(`.${fileType}`))) {
        setFile(uploadedFile);
        return false;
      }
      message.error(`${name} ${formatErrorTrans} ${fileType}`);
      return LIST_IGNORE;
    },
    [formatErrorTrans, setFile],
  );

  const handleOnConfirm = useCallback(() => {
    const title = getFieldValue('title');
    const description = getFieldValue('description');
    const fileKey = snakeCase(`${title}-${patientId}-${moment().unix()}`);
    dispatch(
      postModel(file, patientId, title, description, ({ loaded, total }) =>
        dispatch(
          setUploadingModel({ fileKey, title, progress: Math.round((loaded * 100) / total) }),
        ),
      ),
    ).then(() => {
      dispatch(deleteUploadingModel(fileKey));
      notification.close(fileKey);
    });
  }, [getFieldValue, patientId, dispatch, file]);

  const handleOnChange = useCallback(({ target: { value } }) => setIsTitle(!!value), [setIsTitle]);

  return (
    <Generic
      disabled={!file || !isTitle}
      closeModal={closeModal}
      customTitle={addTrans}
      onClose={handleOnConfirm}
      onConfirm={onConfirm}
      status={status}
      type={type}
      withStatusIcon={false}
    >
      <StyledForm form={form} {...formItemLayout}>
        <StyledDragger {...draggerProps} beforeUpload={handleBeforeUpload} isDisabled={!!file}>
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">{pickTrans}</p>
        </StyledDragger>
        <Item
          label={nameTrans}
          name="title"
          rules={[{ required: true, message: nameErrorTrans }]}
          initialValue={snakeCase(
            deburr(`${firstName}_${lastName}_${modelsAmount + uploadingAmount + 1}`),
          )}
          onChange={handleOnChange}
        >
          <Input />
        </Item>
        <Item label={descriptionTrans} name="description">
          <Input.TextArea />
        </Item>
      </StyledForm>
    </Generic>
  );
};

StlModel.propTypes = {
  closeModal: PropTypes.func,
  data: PropTypes.object,
  onConfirm: PropTypes.func,
  status: PropTypes.number,
  type: PropTypes.string,
};

StlModel.defaultProps = {
  closeModal: noop,
  data: {},
  onConfirm: noop,
  status: STATUS_OK,
  type: TYPE_OK,
};

export default StlModel;
