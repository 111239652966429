import produce from 'immer';

const readObjProps = (obj = {}, props = []) =>
  props.reduce((nestedObj, prop) => nestedObj[prop], obj);

export const pending = (state) =>
  produce(state, (draft) => {
    draft.isLoading = true;
    draft.error = null;
  });
export const fulfilled = (state, { payload: { data } }) =>
  produce(state, (draft) => {
    draft.isLoading = false;
    draft.didLoad = true;
    draft.data = data;
  });
export const rejected = (
  state,
  {
    payload: {
      response: {
        data: { description },
      },
    },
  },
) =>
  produce(state, (draft) => {
    draft.isLoading = false;
    draft.didLoad = false;
    draft.error = description;
  });

export const didLoad = (state, resource = []) => readObjProps(state, resource.concat(['didLoad']));
export const isLoading = (state, resource = []) =>
  readObjProps(state, resource.concat(['isLoading']));
export const initialState = (fields = {}) => ({
  isLoading: false,
  didLoad: false,
  error: null,
  data: [],
  ...fields,
});
export const getData = (state, resource = []) => readObjProps(state, resource.concat(['data']));
export const getError = (state, resource = []) => readObjProps(state, resource.concat(['error']));
