import memoize from 'fast-memoize';
import React, { useCallback, useState } from 'react';

const withData = (Component) => (props) => {
  const [data, setData] = useState({});

  const handleOnClick = memoize((clickedData) => setData(clickedData));
  const handleOnClose = useCallback(() => setData({}), [setData]);

  return (
    <Component handleOnClick={handleOnClick} handleOnClose={handleOnClose} data={data} {...props} />
  );
};

export default withData;
